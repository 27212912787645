import { createAction } from 'redux-act';
import { toastr } from 'react-redux-toastr';

import { firebaseError } from 'utils';
import { fetchCollection } from '../api/rtdb';
import { fetchCollection as fetchUsers } from '../api';

export const TEAMS_FETCH_TEAMS_INIT = createAction('TEAMS_FETCH_TEAMS_INIT');

export const TEAMS_FETCH_TEAMS_SUCCESS = createAction(
  'TEAMS_FETCH_TEAMS_SUCCESS'
);

export const TEAMS_FETCH_TEAMS_FAIL = createAction('TEAMS_FETCH_TEAMS_FAIL');

export const TEAMS_CLEAN_UP = createAction('TEAMS_CLEAN_UP');

export const TEAMS_SELECTED_TEAM = createAction('TEAMS_SELECTED_TEAM');

export const TEAMS_FETCH_TEAM_USERS_INIT = createAction(
  'TEAMS_FETCH_TEAM_USERS_INIT'
);

export const TEAMS_FETCH_TEAM_USERS_SUCCESS = createAction(
  'TEAMS_FETCH_TEAM_USERS_SUCCESS'
);

export const TEAMS_FETCH_TEAM_USERS_FAIL = createAction(
  'TEAMS_FETCH_TEAM_USERS_FAIL'
);

export const fetchTeams = () => {
  return async (dispatch, getState) => {
    dispatch(TEAMS_FETCH_TEAMS_INIT());
    const { locale } = getState().preferences;
    const { isAdmin, id } = getState().auth.userData;

    let teams;
    try {
      teams = await fetchCollection(
        'teams',
        isAdmin
          ? {
              filterBy: 'users',
              value: id,
              isArray: true,
            }
          : {}
      );
    } catch (error) {
      const errorMessage = firebaseError(error, locale);
      toastr.error('', errorMessage);
      return dispatch(TEAMS_FETCH_TEAMS_FAIL({ error }));
    }

    return dispatch(
      TEAMS_FETCH_TEAMS_SUCCESS({
        teams,
      })
    );
  };
};

export const teamsCleanUp = () => (dispatch) => dispatch(TEAMS_CLEAN_UP());

export const fetchTeamUsers = (teamId) => {
  return async (dispatch, getState) => {
    dispatch(TEAMS_FETCH_TEAM_USERS_INIT());
    const { locale } = getState().preferences;

    let users;
    try {
      users = await fetchUsers('users', {
        filterBy: 'teamId',
        value: teamId,
      });
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(TEAMS_FETCH_TEAM_USERS_FAIL({ error }));
    }

    dispatch(TEAMS_SELECTED_TEAM({ teamId }));

    return dispatch(
      TEAMS_FETCH_TEAM_USERS_SUCCESS({
        users,
      })
    );
  };
};
