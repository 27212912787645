/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import classNames from 'classnames';

import { fetchTeamUsers } from 'state/actions/teams';
import { useFormatDate, useFormatMessage } from 'hooks';
import userDefaultLogo from 'assets/user-default-log.jpg';
import teamDeaultLogo from 'assets/team-default-log.png';

const MediaCard = ({ content, isUser }) => {
  const { teamId } = useSelector(
    (state) => ({
      teamId: state.teams.teamId,
    }),
    shallowEqual
  );

  const { id, imageUrl, updated_at, email, name, status } = content;

  const dispatch = useDispatch();

  const onSelectedHandler = () => {
    if (!isUser && id !== teamId) {
      dispatch(fetchTeamUsers(id));
    }
  };

  const date = updated_at ? new Date(updated_at) : new Date();

  return (
    <div
      onClick={onSelectedHandler}
      className={classNames('media', {
        'has-background-grey-lighter': id === teamId && !isUser,
      })}
      style={{
        marginTop: 'auto',
        marginBottom: 'auto',
        paddingBottom: '1rem',
        cursor: !isUser && id !== teamId && 'pointer',
      }}
      data-testid="media"
    >
      <figure className="media-left">
        <p className="image is-64x64">
          <img
            className="is-rounded"
            style={{ height: '100%', width: '100%' }}
            src={imageUrl || (isUser ? userDefaultLogo : teamDeaultLogo)}
            alt="Profile logo preview"
          />
        </p>
      </figure>
      <div className="media-content">
        <div className="content">
          <p className="media-meta">
            <strong>{name}</strong>{' '}
            <small className="has-text-grey">
              {useFormatMessage('MediaCard.updatedAt')}
              {useFormatDate(date, {
                weekday: 'short',
                year: 'numeric',
                month: 'short',
                day: 'numeric',
              })}
            </small>
          </p>
          {isUser && (
            <>
              <p>Status: {` ${status}`}</p>
              <p>{email}</p>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

MediaCard.defaultProps = {
  isUser: false,
};

export default MediaCard;
