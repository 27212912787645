import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import classNames from 'classnames';
import ClipLoader from 'react-spinners/ClipLoader';

import { useFormatMessage, useFormatDate } from 'hooks';
import Table from 'components/Table';
import { fetchMembers, membersCleanUp } from 'state/actions/members';

import classes from './ICMembers.module.scss';

const ICMembers = () => {
  const { membersList, error, loading } = useSelector(
    (state) => ({
      membersList: state.members.data,
      error: state.members.error,
      loading: state.members.loading,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  const [search, setSearch] = useState('');

  useEffect(() => {
    dispatch(fetchMembers());

    return () => dispatch(membersCleanUp());
  }, [dispatch]);

  const columns = [
    {
      Header: '',
      accessor: 'imageUrl',
      Cell: ({ row }) => (
        <div className="image">
          <img src={row.original.imageUrl} alt="" className="is-rounded" />
        </div>
      ),
      disableSortBy: true,
    },
    {
      Header: useFormatMessage('ICMembers.name'),
      accessor: 'full_name',
    },
    {
      Header: useFormatMessage('ICMembers.email'),
      accessor: 'email',
    },
    {
      Header: useFormatMessage('ICMembers.status'),
      accessor: 'status',
    },
    {
      Header: useFormatMessage('ICMembers.Team'),
      accessor: 'team_name',
    },
    {
      Header: useFormatMessage('ICMembers.updatedAt'),
      accessor: 'updated_at',
      Cell: ({ row }) => (
        <small className="has-text-grey is-abbr-like">
          {useFormatDate(row.original.updated_at, {
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: 'numeric',
          })}
        </small>
      ),
    },
  ];

  const data = search
    ? membersList.filter((el) => {
        const clonedElem = { ...el };
        delete clonedElem.team_id;
        delete clonedElem.id;
        delete clonedElem.first_name;
        delete clonedElem.last_name;
        delete clonedElem.updated_at;
        return Object.values(clonedElem).some((field) =>
          field.toLowerCase().includes(search.toLowerCase())
        );
      })
    : membersList;

  return (
    <>
      <section className="hero is-hero-bar">
        <div className="hero-body">
          <div className="level">
            <div className="level-left">
              <div className="level-item">
                <h1 className="title">
                  {useFormatMessage('ICMembers.members')}
                </h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section is-main-section">
        <div className="card has-table has-mobile-sort-spaced">
          <header className="card-header">
            <p className={classNames('card-header-title', classes.tableHeader)}>
              <span>{useFormatMessage('ICMembers.search')}</span>
              <input
                type="text"
                className="input"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </p>
          </header>
          <div className="b-table">
            {loading ? <ClipLoader /> : <Table columns={columns} data={data} />}
            {error && 'Show error'}
          </div>
        </div>
      </section>
    </>
  );
};

export default ICMembers;
