import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { useFormatMessage } from 'hooks';
import paths from 'pages/Router/paths';
import NavLink from '../Link';

const Aside = ({ handleMobileToggle }) => {
  const usersMessage = useFormatMessage('Aside.users');

  return (
    <aside className="aside is-placed-left is-expanded">
      <Link to={paths.ROOT} className="aside-tools">
        <div className="aside-tools-label">
          <span>
            <b>Battle Planner</b>
          </span>
        </div>
      </Link>
      <div className="menu is-menu-main">
        <ul className="menu-list">
          <li>
            <NavLink
              to={paths.ROOT}
              className="has-icon"
              onClick={handleMobileToggle}
            >
              <span className="icon">
                <i className="mdi mdi-home" />
              </span>
              <span className="menu-item-label">
                {useFormatMessage('Aside.home')}
              </span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to={paths.USERS}
              className="has-icon"
              onClick={handleMobileToggle}
            >
              <span className="icon">
                <i className="mdi mdi-account-supervisor" />
              </span>
              <span className="menu-item-label">{usersMessage}</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to={paths.TEAMS}
              className="has-icon"
              onClick={handleMobileToggle}
            >
              <span className="icon">
                <i className="mdi mdi-account-group" />
              </span>
              <span className="menu-item-label">
                {useFormatMessage('Aside.teams')}
              </span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to={paths.ICMEMBERS}
              className="has-icon"
              onClick={handleMobileToggle}
            >
              <span className="icon">
                <i className="mdi mdi-account-multiple-check" />
              </span>
              <span className="menu-item-label">
                {useFormatMessage('Aside.iCMembers')}
              </span>
            </NavLink>
          </li>
        </ul>
      </div>
    </aside>
  );
};

Aside.propTypes = {
  handleMobileToggle: PropTypes.func.isRequired,
};

export default Aside;
