import { createReducer } from 'redux-act';

import {
  TEAMS_FETCH_TEAMS_INIT,
  TEAMS_FETCH_TEAMS_SUCCESS,
  TEAMS_FETCH_TEAMS_FAIL,
  TEAMS_CLEAN_UP,
  TEAMS_SELECTED_TEAM,
  TEAMS_FETCH_TEAM_USERS_FAIL,
  TEAMS_FETCH_TEAM_USERS_INIT,
  TEAMS_FETCH_TEAM_USERS_SUCCESS,
} from 'state/actions/teams';

const initialState = {
  teamsList: [],
  usersList: [],
  loadingTeams: false,
  loadingUsers: false,
  error: null,
  teamId: 0,
};

export const teamsReducer = createReducer(
  {
    [TEAMS_FETCH_TEAMS_INIT]: () => ({
      ...initialState,
      loadingTeams: true,
    }),
    [TEAMS_FETCH_TEAMS_SUCCESS]: (state, payload) => ({
      ...state,
      teamsList: payload.teams,
      loadingTeams: false,
      error: null,
    }),
    [TEAMS_FETCH_TEAMS_FAIL]: (state, payload) => ({
      ...state,
      loadingTeams: false,
      error: payload.error,
    }),
    [TEAMS_CLEAN_UP]: (state) => ({
      ...state,
      loadingTeams: false,
      error: null,
    }),
    [TEAMS_SELECTED_TEAM]: (state, payload) => ({
      ...state,
      teamId: payload.teamId,
    }),
    [TEAMS_FETCH_TEAM_USERS_INIT]: (state) => ({
      ...state,
      loadingUsers: true,
    }),
    [TEAMS_FETCH_TEAM_USERS_SUCCESS]: (state, payload) => ({
      ...state,
      usersList: payload.users || [],
      loadingUsers: false,
      error: null,
    }),
    [TEAMS_FETCH_TEAM_USERS_FAIL]: (state, payload) => ({
      ...state,
      loadingUsers: false,
      error: payload.error,
    }),
  },
  initialState
);
