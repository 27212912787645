import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import ClipLoader from 'react-spinners/ClipLoader';

import { useFormatMessage } from 'hooks';
import Table from 'components/Table';
import { fetchUsers, deleteUser, usersCleanUp } from 'state/actions/users';
import paths from 'pages/Router/paths';
import ConfirmationModal from 'components/ConfirmationModal';
import { closeModal, openModal } from 'utils';
import defaultLogo from 'assets/user-default-log.jpg';

import classes from './Users.module.scss';

const Users = () => {
  const { usersList, error, loading, deleted } = useSelector(
    (state) => ({
      usersList: state.users.data,
      error: state.users.error,
      loading: state.users.loading,
      deleted: state.users.deleted,
    }),
    shallowEqual
  );

  const [deleteModal, setDeleteModal] = useState({
    id: null,
    isOpen: false,
  });

  const dispatch = useDispatch();

  const [search, setSearch] = useState('');

  useEffect(() => {
    dispatch(fetchUsers());

    return () => dispatch(usersCleanUp());
  }, [dispatch]);

  useEffect(() => {
    if (deleted && !loading) {
      setDeleteModal((prevState) => ({
        id: null,
        isOpen: !prevState.isOpen,
      }));
    }
  }, [deleted, loading]);

  const onCloseModalHandler = () => {
    closeModal(setDeleteModal);
  };

  const onDeleteUserHandler = () => {
    dispatch(deleteUser(deleteModal.id));
  };

  const columns = [
    {
      Header: '',
      accessor: 'imageUrl',
      Cell: ({ row }) => (
        <div className="image">
          <img
            src={row.original.imageUrl || defaultLogo}
            alt=""
            className="is-rounded"
            style={{ height: '100%', width: '100%' }}
          />
        </div>
      ),
      disableSortBy: true,
    },
    {
      Header: useFormatMessage('Users.name'),
      accessor: 'name',
    },
    {
      Header: useFormatMessage('Users.email'),
      accessor: 'email',
    },
    {
      Header: useFormatMessage('Users.age'),
      accessor: 'age',
    },
    {
      Header: useFormatMessage('Users.gender'),
      accessor: 'gender',
    },
    {
      Header: useFormatMessage('Users.phone'),
      accessor: 'phone',
    },
    {
      Header: useFormatMessage('Users.zipCode'),
      accessor: 'zipCode',
    },
    {
      Header: useFormatMessage('Users.team'),
      accessor: 'teamName',
    },
    {
      Header: useFormatMessage('Users.membership'),
      accessor: 'status',
    },
    {
      Header: '',
      id: 'actions',
      accessor: 'actions',
      Cell: ({ row }) => (
        <>
          {!row.original.isAdmin && (
            <div className="buttons is-right">
              <Link
                to={`/users/${row.original.id}`}
                className="button is-small is-primary"
              >
                <span className="icon is-small">
                  <i className="mdi mdi-account-edit" />
                </span>
              </Link>

              <button
                type="button"
                className="button is-small is-danger"
                onClick={() => openModal(setDeleteModal, row.original.id)}
              >
                <span className="icon is-small">
                  <i className="mdi mdi-trash-can" />
                </span>
              </button>
            </div>
          )}
        </>
      ),
      disableSortBy: true,
    },
  ];

  const data = search
    ? usersList.filter((el) => {
        const clonedElem = { ...el };
        delete clonedElem.id;
        delete clonedElem.isAdmin;
        delete clonedElem.gender;
        delete clonedElem.imageUrl;
        delete clonedElem.age;
        delete clonedElem.teamId;
        delete clonedElem.gender;
        delete clonedElem.phone;
        delete clonedElem.zipCode;
        return Object.values(clonedElem).some(
          (field) =>
            field &&
            field.toString().toLowerCase().includes(search.toLowerCase())
        );
      })
    : usersList;

  const deleteMessage = useFormatMessage('Users.delete');

  const confirmMessage = useFormatMessage('Users.confirm');

  const permDeleteMessage = useFormatMessage('Users.permDelete');

  const cancelMessage = useFormatMessage('Users.cancel');

  return (
    <>
      {deleteModal.isOpen && (
        <ConfirmationModal
          isActive={deleteModal.isOpen}
          isLoading={loading}
          confirmButtonMessage={deleteMessage}
          title={confirmMessage}
          body={permDeleteMessage}
          cancelButtonMessage={cancelMessage}
          onConfirmation={onDeleteUserHandler}
          onCancel={onCloseModalHandler}
        />
      )}
      <section className="hero is-hero-bar">
        <div className="hero-body">
          <div className="level">
            <div className="level-left">
              <div className="level-item">
                <h1 className="title">{useFormatMessage('Users.users')}</h1>
              </div>
            </div>
            <div className="level-right">
              <div className="level-item">
                <Link to={paths.ADD_USER} className="button">
                  {useFormatMessage('Users.newUser')}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section is-main-section">
        <div className="card has-table has-mobile-sort-spaced">
          <header className="card-header">
            <p className={classNames('card-header-title', classes.tableHeader)}>
              <span>{useFormatMessage('Users.search')}</span>
              <input
                type="text"
                className="input"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </p>
          </header>
          <div className="b-table">
            {loading ? <ClipLoader /> : <Table columns={columns} data={data} />}
            {error && 'Show error'}
          </div>
        </div>
      </section>
    </>
  );
};

export default Users;
