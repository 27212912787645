import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { fetchBattlePlan } from 'state/actions/users';
import { getCurrentQuarter, categories, plans } from 'utils';
import loadingGif from 'assets/loading.gif';
import orderOfManLogo from 'assets/order-of-man.png';
import classes from './Plan.module.scss';

const Plan = () => {
  const { id, quarter } = useParams();

  const { loading, userData, battlePlan } = useSelector(
    (state) => ({
      loading: state.users.loading,
      userData: state.users.user,
      battlePlan: state.users.battlePlan,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  const selectedQuarter = quarter || getCurrentQuarter();

  useEffect(() => {
    if (!userData) {
      dispatch(fetchBattlePlan(id, selectedQuarter));
    }
  }, [id, userData, dispatch]);

  useEffect(() => {
    document.documentElement.classList.remove('has-aside-expanded');

    return () => document.documentElement.classList.add('has-aside-expanded');
  }, []);

  return (
    <div className={classes.plan}>
      <div className={classes.planHeader}>
        <div>
          <h1 className={classes.planTitle}>12-WEEK BATTLE PLAN</h1>
          {userData && (
            <h2 className={classes.planSubTitle}>
              {userData.name} ({selectedQuarter})
            </h2>
          )}
        </div>
        <div>
          <img
            className={classes.orderOfManLogo}
            src={orderOfManLogo}
            alt="Order Of Man"
          />
        </div>
      </div>
      {loading && (
        <img className={classes.loading} src={loadingGif} alt="Loading" />
      )}
      {userData && (
        <>
          <hr />
          <div className={classes.block}>
            <h3 className={classes.myVision}>MY COMPELLING VISION</h3>
            <p className={classes.myVisionText}>
              {battlePlan['MY VISION']?.text}
            </p>
          </div>
          {categories.map((category) => (
            <div key={category.id} className={classes.block}>
              <h3 className={classes[category.id.toLowerCase()]}>
                {category.id}
              </h3>
              {plans.map((plan) => (
                <p key={plan.id}>
                  <strong>{plan.label}: </strong>
                  {battlePlan[category.id][plan.id]?.text}
                </p>
              ))}
            </div>
          ))}
        </>
      )}
      <hr />
      <div className={classes.block}>
        <h3 className={classes.question}>
          DON’T HAVE THE 12-WEEK BATTLE PLANNER APP YET?{' '}
        </h3>
        <a
          href="https://12weekbattleplanner.com/download/"
          target="_blank"
          rel="noreferrer"
          className={classes.button}
        >
          GET THE APP NOW
        </a>
      </div>
      <hr />
      <p className={classes.disclaimer}>
        ©2021 Order of Man, LLC •{' '}
        <a
          href="https://12weekbattleplanner.com"
          target="_blank"
          rel="noreferrer"
        >
          12weekbattleplanner.com
        </a>
      </p>
    </div>
  );
};

export default Plan;
