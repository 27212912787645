import { createAction } from 'redux-act';
import { toastr } from 'react-redux-toastr';

import { firebaseError } from 'utils';
import { checkUserData } from './auth';
import { fetchCollection } from '../api/rtdb';

export const MEMBERS_FETCH_DATA_INIT = createAction('MEMBERS_FETCH_DATA_INIT');
export const MEMBERS_FETCH_DATA_SUCCESS = createAction(
  'MEMBERS_FETCH_DATA_SUCCESS'
);
export const MEMBERS_FETCH_DATA_FAIL = createAction('MEMBERS_FETCH_DATA_FAIL');

export const MEMBERS_CLEAN_UP = createAction('MEMBERS_CLEAN_UP');

export const fetchMembers = () => {
  return async (dispatch, getState) => {
    dispatch(checkUserData());
    const { locale } = getState().preferences;

    dispatch(MEMBERS_FETCH_DATA_INIT());

    let members;

    try {
      members = await fetchCollection('ic_members');
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(MEMBERS_FETCH_DATA_FAIL({ error }));
    }

    return dispatch(
      MEMBERS_FETCH_DATA_SUCCESS({
        data: members,
      })
    );
  };
};

export const membersCleanUp = () => (dispatch) => dispatch(MEMBERS_CLEAN_UP());
