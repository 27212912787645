/* eslint-disable no-nested-ternary */
/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import ClipLoader from 'react-spinners/ClipLoader';

import { useFormatMessage } from 'hooks';
import MediaCard from 'components/MediaCard';
import { fetchTeams, teamsCleanUp } from 'state/actions/teams';

const TeamsCard = () => {
  const { loading, dataList } = useSelector(
    (state) => ({
      loading: state.teams.loadingTeams,
      dataList: state.teams.teamsList,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  const [search, setSearch] = useState('');

  useEffect(() => {
    dispatch(fetchTeams());

    return () => {
      dispatch(teamsCleanUp());
    };
  }, [dispatch]);

  const data = search
    ? dataList.filter((el) => {
        const clonedElem = { ...el };
        delete clonedElem.id;
        delete clonedElem.members;
        return Object.values(clonedElem).some((field) =>
          field.toLowerCase().includes(search.toLowerCase())
        );
      })
    : dataList;

  const availableTeamsMessage = useFormatMessage('TeamsCard.allTeams');

  const searchByMessage = useFormatMessage('TeamsCard.searchBy');

  return (
    <div className="card has-height-medium">
      <header className="card-header">
        <p className="card-header-title">
          <span className="icon">
            <i className="mdi mdi-account-group default" />
          </span>
          <span>{availableTeamsMessage}</span>
        </p>
      </header>
      <div>
        <div
          className="field-body"
          style={{
            padding: '0.5rem 0.25rem',
            borderBottom: '1px solid rgba(24,28,33,0.06)',
          }}
        >
          <div className="field">
            <div className="control is-expanded">
              <input
                placeholder={searchByMessage}
                type="text"
                className="input search"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                style={{ height: '38px' }}
              />
            </div>
          </div>
          <div className="field">
            <div className="control is-expanded">
              <div style={{ minWidth: '220px' }} />
            </div>
          </div>
        </div>
      </div>
      <div
        className="card-content ps ps--active-y"
        style={{
          padding: '0px 1.5rem',
          overflow: 'auto',
          maxHeight: '700px',
        }}
      >
        {loading ? (
          <ClipLoader />
        ) : (
          <div className="media-list">
            {data &&
              data.map((value) => <MediaCard content={value} key={value.id} />)}
          </div>
        )}
      </div>
    </div>
  );
};

export default TeamsCard;
